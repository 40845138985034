import NERTC from "./NIM_Web_NERTC_v4.5.1";//实时音视频通话SDK
// ============
//创建client实例
var rtc = {}
rtc.client = NERTC.createClient({
    appkey: 'f2da2439fcdac8151ce4ff357ffd6698', //您的 appkey
    debug: true, //是否开启调试日志
});

export function getJoinRoom (ele){
    //加入房间
rtc.client.join({
    channelName: ele,
    uid: 123, 
    token: ''
}).then((obj) => {
    console.log(obj);
    console.info('加入房间成功...')
    //初始化本地流，并且发布
    initLocalStream() //后面介绍说明
})
}


// 获取 麦克风 和摄像头 
export function initLocalStream() {
    //创建本端stream实例，销毁前无需重复创建
    var microphoneId
    NERTC.getDevices().then((devices) => {
        console.log(devices);
        var audioDevices = devices.audioIn;  //数组，麦克风设备列表
         microphoneId = audioDevices[0]
        var videoDevices = devices.video;  //数组，摄像头设备列表
    })
    rtc.localStream = NERTC.createStream({
        uid: "123",                      // 本端的uid
        audio: true,                   // 是否从麦克风采集音频
        microphoneId: microphoneId,    // 麦克风设备 deviceId，通过 getMicrophones() 获取
        video: false,                   // 是否从摄像头采集视频
        //   cameraId: cameraId             // 摄像头设备 deviceId，通过 getCameras() 获取
    })
    //启动本地音视频流，销毁前无需重复初始化
    rtc.localStream.init().then(() => {
        console.warn('音视频初始化完成，播放本地视频')
        console.log(rtc.localStream);
        
        // 将本地音视频流发布至云信服务器，加入房间前不用执行此方法。
        rtc.client.publish(rtc.localStream).then(() => {
            console.warn('本地 publish 成功')
        })
    })
}


//导出
export default NERTC;
