<template>
  <div class="">
    <el-container class="container">
      <el-aside width="290px">
        <div class="input">
          <el-input
            prefix-icon="el-icon-search"
            v-model="store"
            placeholder="搜索"
            @keydown.native="getKeyWord()"
          ></el-input>
        </div>
        <div class="asideTab">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="最近会话" name="session">
              <span slot="label">
                <img
                  v-if="activeName == 'session'"
                  src="~@/assets/new-icon/icon_news.png"
                  alt=""
                />
                <img v-else src="~@/assets/new-icon/icon_news_ac.png" alt="" />
              </span>
              <div class="sessionWarp">
                <div
                  :class="{
                    sessionLi: true,
                    delimit: infoClass == item.id,
                    active: active == index,
                  }"
                  v-for="(item, index) in Recently"
                  :key="index"
                  @click="dialogue(item, index, 1)"
                  @mouseover="getinfoClass(item)"
                  @mouseleave="infoClass = ''"
                >
                  <!-- 头像 -->
                  <span v-if="item.scene == 'team'" class="Head">群聊</span>
                  <span v-if="item.scene == 'p2p'" class="Head">{{
                    item.to.substring(item.to.length - 2)
                  }}</span>
                  <!-- <span v-else class="Head">
                    {{  item.lastMsg.from.substring(
                      item.lastMsg.to.length - 2
                    )}}
                  </span> -->
                  <div class="p sessionSpan">
                    <span v-if="item.lastMsg.fromNick" class="title">{{
                      item.lastMsg.fromNick
                    }}</span>
                    <span v-else-if="item.lastMsg.to" class="title">{{
                      item.lastMsg.to
                    }}</span>
                    <span class="rem">{{ item.lastMsg.text }}</span>
                  </div>
                  <div class="state">
                    <!-- <span class="isTrue">在线</span> -->
                    <!-- <span class="isFalse">离线</span> -->
                  </div>
                  <div class="state" v-if="item.scene == 'team'">
                    <span>群聊</span>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="通讯录" name="contact">
              <span slot="label">
                <img
                  v-if="activeName == 'contact'"
                  src="~@/assets/new-icon/icon_Friend_ac.png"
                  alt=""
                />
                <img v-else src="~@/assets/new-icon/icon_Friend.png" alt="" />
              </span>
              <div class="addressBook">
                <div class="add" @click="addition">
                  <span class="th">
                    <img src="~@/assets/new-icon/Group3.png" alt="" />
                  </span>
                  <span>添加好友</span>
                </div>
                <!-- 好友列表 -->
                <div class="FriendsList">
                  <!-- :class="{FriendsLi = true,acticn :userChen }" -->
                  <div
                    :class="{
                      FriendsLi: true,
                      acticn: userChen == index,
                      addCticn: addUserChen == index,
                    }"
                    v-for="(item, index) in usersList"
                    :key="index"
                    @mouseover="getuserChen(index)"
                    @mouseleave="userChen = null"
                    @click="addChat(item, index)"
                  >
                    <span class="Head" v-if="item.nick.length > 1">{{
                      item.nick.substring(item.nick.length - 2)
                    }}</span>
                    <span v-else class="Head" >{{ item.nick }}</span>
                    <span class="title">{{ item.nick }}</span>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="群列表" name="group">
              <span slot="label">
                <img
                  v-if="activeName == 'group'"
                  src="~@/assets/new-icon/icon_group_foucs_ac.png"
                  alt=""
                />
                <img v-else src="~@/assets/new-icon/icon_group.png" alt="" />
              </span>
              <!-- 群列表 -->
              <div class="sessionWarp">
                <div
                  :class="{
                    sessionLi: true,
                    delimit: delimitClass == item.teamId,
                  }"
                  v-for="(item, index) in tabsList"
                  :key="index"
                  @click="getGroup(item)"
                  @mousemove="getdelimit(item)"
                  @mouseout="delimitClass = ''"
                >
                  <div class="p Group">
                    <!-- <span class="Head">{{
                      item.name.substring(item.name.length - 2)
                    }}</span> -->
                    <span class="title">{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-aside>
      <el-container>
        <el-header>
          <div class="accountName">
            <span>{{ accountName }}</span>
          </div>
          <!-- 群资料显示  -->
          <div class="GroupData" @click="getUnfold" v-if="scenario == 'team'">
            <span>...</span>
          </div>
        </el-header>
        <el-main id="main" ref="qwcontent" class="main">
          <div class="wapr">
            <!-- 单聊 -->
            <div class="content" v-if="scenario == 'p2p'">
              <div
                :class="item.flow == 'in' ? 'closed ' : 'hair'"
                v-for="(item, index) in sessions"
                :key="index"
              >
                <!-- 头像 -->
                <span class="Head" v-if="item.from.length > 1">{{
                  item.from.substring(item.from.length - 2)
                }}</span>
                <span class="Head" v-else>{{ item.fromNick }}</span>
                <!-- 信息内容 -->
                <span
                  class="itemText"
                  v-if="item.type == 'text' || item.type == 'custom'"
                  >{{ item.text }}</span
                >
                <!-- 点击播放语音 -->
                <div v-if="item.type == 'audio'">
                  <span @click="playAudio(item, index)" class="itemAudio">
                    <img v-if="item.isbj == true" :src="isplayUrl" alt="" />
                    <img v-if="item.isbj == false" :src="isPerform" alt="" />
                    {{ parseInt(item.file.dur / 1000) + "''" }}
                  </span>
                  <!-- <span class="interpret">转文字</span> -->
                </div>
              </div>
            </div>
            <!-- 群聊 -->
            <div class="content" v-if="scenario == 'team'">
              <div
                :class="item.flow == 'in' ? 'closed ' : 'hair'"
                v-for="(item, index) in GroupMessage"
                :key="index"
              >
                <!-- 头像 -->
                <span class="Head" v-if="item.fromNick.length > 1">{{
                  item.fromNick.substring(item.fromNick.length - 2)
                }}</span>
                <span class="Head" v-else>{{
                  item.fromNick.substring(item.fromNick.length - 2)
                }}</span>
                <!-- 信息内容 -->
                <span v-if="item.text" class="itemText">{{ item.text }}</span>
                <!-- 点击播放语音 -->
                <div v-if="item.type == 'audio'">
                  <span @click="playAudio(item, index)" class="itemAudio">
                    <img v-if="item.isbj == true" :src="isplayUrl" alt="" />
                    <img v-if="item.isbj == false" :src="isPerform" alt="" />
                    {{ parseInt(item.file.dur / 1000) + "''" }}
                  </span>
                  <!-- <span class="interpret">转文字</span> -->
                </div>
                <!-- <span v-else>其他消息类型去设备端查看</span> -->
              </div>
            </div>
          </div>
        </el-main>
        <el-footer class="" v-if="accountId">
          <div class="fun">
            <div class="li">
              <!-- 文件 -->
              <!-- <img src="~@/assets/comm/a1.png" alt="" /> -->
              <!-- 截图 -->
              <!-- <img class="img-cetr" src="~@/assets/comm/a2.png" alt="" /> -->
              <!-- v-show="scenario !== 'team'" -->
              <div class="img-left">
                <img
                  @click="getVoice"
                  v-if="Voice"
                  src="~@/assets/comm/a4.png"
                  alt=""
                />
                <div class="time" v-if="Voice == false" @click="onEndVoice">
                  <!-- 语音 -->
                  <div class="voiceong">
                    <!-- <span class="voiceTime">00:60</span> -->
                    <img src="~@/assets/comm/statebg.gif" alt="" />
                  </div>

                  <img src="~@/assets/comm/a5.png" alt="" />
                </div>
              </div>
            </div>
            <div class="li">
              <!-- 电话 -->
              <!-- <img
                class="img-right"
                @click="getCall"
                src="~@/assets/comm/a3.png"
                alt=""
              /> -->
            </div>
          </div>
          <div class="inputDeep">
            <el-input
              @input="lenMin()"
              maxlength="400"
              type="textarea"
              :rows="4"
              placeholder="聊一聊吧~"
              v-model="textarea"
              @keydown.native="listen($event)"
            >
            </el-input>
            <div class="btn">
              <!-- //按下 -->
              <!-- //抬起 -->
              <!-- <el-button
                :class="intercom ? 'send' : ''"
                @mousedown.native="downTeam"
                @mouseup.native="seupTeam"
                >{{ intercomText }}</el-button
              > -->

              <el-button
                :class="textarea ? 'send' : 'sends'"
                @click="sendCustomMsg"
                >发送文字</el-button
              >
            </div>
          </div>
        </el-footer>
      </el-container>
    </el-container>

    <el-dialog
      title="添加好友"
      :visible.sync="dialogVisible"
      width="40rem"
      :before-close="handleClose"
      :modal-append-to-body="false"
      class="dialogAdd"
    >
      <div v-if="isfindInfo == '2'">
        <div class="findInfo">
          <img src="~@/assets/new-icon/default-icon.png" alt="" />
          <div class="text">
            <span>呢称：{{ findInfo.nick }}</span>
            <span class="s1">账号:{{ findInfo.account }}</span>
          </div>
        </div>
      </div>
      <div v-if="isfindInfo == '1'">
        <el-input
          v-model="account"
          placeholder="请输入账号"
          clearable
        ></el-input>
      </div>
      <span v-if="isfindInfo == '1'" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getConfirm">确 定</el-button>
      </span>
      <span v-if="isfindInfo == '2'" slot="footer" class="dialog-footer">
        <el-button @click="isfindInfo = 1">继续搜索</el-button>
        <el-button type="primary" @click="getApply()">加为好友</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="群资料"
      :visible.sync="GroupDialogVisible"
      width="500px"
      max-height="530px"
      :before-close="GroupHandleClose"
      :modal-append-to-body="false"
      @open="getOpen"
      class="dialogGroup"
    >
      <div class="GroupWarp">
        <div
          @click="addChum(item)"
          class="li"
          v-for="(item, index) in membersList"
          :key="index"
        >
          <img
            slot="reference"
            src="@/assets/new-icon/default-icon.png"
            alt=""
          />
          <span slot="reference" v-if="item.nickInTeam">{{
            item.nickInTeam
          }}</span>
          <span slot="reference" v-else>{{ item.account }}</span>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisibleChum"
      width="40rem"
      :modal-append-to-body="false"
      class="dialogAdd"
    >
      <div>
        <div class="findInfo">
          <img src="~@/assets/new-icon/default-icon.png" alt="" />
          <div class="text">
            <!-- <span>呢称：{{ Chumdata.account }}</span> -->
            <span class="s1">账号:{{ Chumdata.nick }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getClum()">加为好友</el-button>
      </span>
    </el-dialog>

    <audio
      id="resource"
      ref="up"
      :src="audioSrc"
      controls
      style="display: none"
    ></audio>
    <!-- <input type="text"> -->
    <audio
      muted
      id="audioVoice"
      controls
      autoplay
      style="display: none"
    ></audio>
  </div>
</template>

<script>
import Record from "@/api/record-sdk"; //引入外部js 录音
import { getMerchant } from "@/api/communication/communication";
import {
  nim,
  pushMsg,
  netcalls,
  onUsers,
  onAddFriend,
  init,
} from "@/utils/NIM_WEB_SDK";
import { upCall, initLocalStream, getJoinRoom } from "@/static/NIM_NERTC_SDK";
import Url from "@/assets/new-icon/icon_audio.png";
import gif from "@/assets/new-icon/play.gif";


export default {
  data() {
    return {
      informationList: [],
      GroupList: [], //群成员
      store: "", //搜索 关键字
      textarea: "", //聊天框内容
      messageStaging: [], //消息暂存
      information: [],
      // 网易云信
      msgdata: "",
      dataInfo: {
        msgs: [],
      },
      // ===========
      // 对话人名称
      accountName: "",
      accountId: "",

      // 对话框
      Voice: true, // 语音
      // ===========

      // 按住讲话
      intercom: false,
      intercomText: "按住讲话",

      sessions: [], //消息
      mute: true, // 静音
      Phone: true, // 静音

      channelId: "", // 通话房间id

      // ====消息 群组 切换
      activeName: "session",
      Recently: [], //最近 会话

      scenario: "", //场景

      // ============
      delimitClass: "", //划入群
      infoClass: "", //划入消息
      active: null, //点击 消息

      GroupMessage: [],
      dialogVisible: false,
      account: "", //搜索 账号

      usersList: [], //好友列表
      userChen: null, //

      findInfo: {
        nick: "123",
        account: "你好呀",
      }, //查找 出来的信息
      isfindInfo: "1",

      audioSrc: "", //语音播放地址
      isplayUrl: Url,
      isPerform: gif,

      addUserChen: null,

      isVoice: false,
      isFinished: false,
      tipMsg: "录音",
      audio: "",
      recorder: new Record(),

      FileInformation: null, //音频文件信息

      // ===========群资料
      GroupDialogVisible: false,
      membersList: [], //群成员列表
      dialogVisibleChum: false, //
      Chumdata: {}, //好友资料
    };
  },
  mounted() {
    init();
    this.$nextTick(() => {
      this.Recently = this.RecentlyList;
      this.usersList = this.users;
    });
  },
  methods: {
    addChum(item) {
      this.dialogVisibleChum = true;

      nim.getUser({
        account: item.account,
        done: getUserDone,
      });
      let tath = this;
      function getUserDone(error, user) {
        console.log("获取用户名片" + (!error ? "成功" : "失败"), error, user);
        if (!error && user) {
          console.log(user);
          // onUsers(user);
          tath.Chumdata = user;
        }
      }

      console.log(item);
    },
    getClum() {
      //群资料加好友
      nim.addFriend({
        account: this.Chumdata.account,
        ps: "好友",
        done: addFriendDone,
      });
      let than = this;
      function addFriendDone(error, obj) {
        console.log(error);
        console.log(obj);
        console.log("直接加为好友" + (!error ? "成功" : "失败"));
        if (!error) {
          than.$message.success("添加成功");
          onAddFriend(obj.friend);
        }
      }
    },
    getOpen() {
      nim.getTeamMembers({
        teamId: this.accountId,
        done: getTeamMembersDone,
      });
      let than = this;
      function getTeamMembersDone(error, obj) {
        console.log(error);
        console.log("获取群成员" + (!error ? "成功" : "失败"));
        if (!error) {
          console.log(obj);
          than.membersList = obj.members;
        }
      }
    },
    getUnfold() {
      this.GroupDialogVisible = true;
    },
    GroupHandleClose() {
      this.GroupDialogVisible = false;
    },
    getKeyWord() {
      this.$message.error("暂未开放");
      // nim.msgFtsInServerByTiming({
      //   keyword: this.store,
      //   done: function (err, obj) {
      //     console.log(err, obj);
      //   },
      // });
    },
    // 好友列表 展开聊天
    addChat(item, index) {
      this.addUserChen = index;
      console.log(item);
      this.accountName = item.account;
      this.accountId = item.account;
      this.scenario = "p2p"; //场景
      this.getMessage(this.scenario);
    },
    playAudio(item, index) {
      //播放语音

      let url = item.file.mp3Url;
      var mp3Url = nim.audioToMp3({
        url: url,
      });
      console.log(mp3Url);
      this.audioSrc = mp3Url;
      var dom = this.$refs.up;
      console.log(dom.canPlayType);

      if (dom.canPlayType) {
        this.isplay = 2;
        dom.play(); //播放
        item.isbj = false;
      } else {
        this.$message({
          type: "error",
          message: "您的浏览器不支持",
        });
      }
      console.log(item.isbj);
      //  计算 播放的时间
      var bur = item.file.dur;
      console.log(bur);
      setTimeout(() => {
        item.isbj = true;
        console.log(Url);
      }, bur);
    },
    getApply() {
      //确认添加
      console.log(this.findInfo);
      nim.addFriend({
        account: this.findInfo.account,
        ps: "好友",
        done: addFriendDone,
      });
      let than = this;
      function addFriendDone(error, obj) {
        console.log(error);
        console.log(obj);
        console.log("直接加为好友" + (!error ? "成功" : "失败"));
        if (!error) {
          // than.friends.from()

          than.$message.success("添加成功");
          onAddFriend(obj.friend);
          // nim.getFriends({
          //   done: getFriendsDone,
          // });
          // function getFriendsDone(error, friends) {
          //   console.log(
          //     "获取好友列表" + (!error ? "成功" : "失败"),
          //     error,
          //     friends
          //   );
          //   if (!error) {
          //     onFriends(friends);
          //   }
          // }
        }
      }
    },
    getuserChen(index) {
      this.userChen = index;
      console.log(index);
    },
    // 添加好友
    addition() {
      this.dialogVisible = true;
    },

    getConfirm() {
      nim.getUser({
        account: this.account,
        done: getUserDone,
      });
      let tath = this;
      function getUserDone(error, user) {
        console.log("获取用户名片" + (!error ? "成功" : "失败"), error, user);
        if (!error && user) {
          console.log(user);
          // onUsers(user);
          tath.findInfo = user;
          tath.isfindInfo = "2";
          console.log(tath.findInfo);
        }
      }
    },
    getdelimit(item) {
      // console.log(item);
      this.delimitClass = item.teamId;
    },
    getinfoClass(item) {
      // console.log(item);
      this.infoClass = item.id;
    },
    // tab 切换
    handleClick() {},
    test() {
      let add = this.$store.getters("callType");
      //    var ele = add.channelId//房间id
      //       getJoinRoom(ele); //初始化本地流并且发布
      //  console.log(add);
      var param = {
        channelId: add.channelId,
        account: "123",
        requestId: add.requestId,
        offlineEnabled: false,
        attachExt: "12",
        autoJoin: false,
        uid: "123",
        joinAttachExt: "join ext",
      };

      nim
        .signalingAccept(param)
        .then((data) => {
          console.warn("独立呼叫信令，接受别人的邀请，data：", data);
          if (autoJoin) {
            console.warn(
              "独立呼叫信令，接受别人的邀请后，自动加入频道成功，data：",
              data
            );
          }
        })
        .catch((error) => {
          console.warn(
            "独立呼叫信令，取消邀请别人加入频道失败，error：",
            error
          );
          if (error.code == 10404) {
            console.warn("独立呼叫信令：频道不存在");
          } else if (error.code == 10408) {
            console.warn("独立呼叫信令：邀请不存在或已过期");
          } else if (error.code == 10409) {
            console.warn("独立呼叫信令：邀请已经拒绝");
          } else if (error.code == 10410) {
            console.warn("独立呼叫信令：邀请已经接受");
          } else if (error.code == 10201) {
            console.warn("独立呼叫信令：对方不在线");
          }
        });
    },
    lenMin() {
      console.log(this.textarea);
      if (this.textarea.length >= 400) {
        this.$message.error("最多只能输入400字");
      }
    },
    // =========
    scrollToBottom() {
      // 重置 滚动条
      this.$nextTick(() => {
        let that = this;
        setTimeout(() => {
          var scrollTop = this.$el.querySelector(".main");
          let arr = scrollTop.scrollHeight + 500;
          that.$refs.qwcontent.$el.scrollTop = arr;
          console.log(that.$refs.qwcontent.$el.scrollTop);
        }, 13);
      });
    },
    getCall() {
      this.$store.commit("tab/callPhone", 2);
      console.log(this.$store.getters.callPhone);

      var timestamp = new Date().getTime();

      var param = {
        type: 1, // 必填字段 通话类型,1:音频;2:视频;3:其他
        channelName: timestamp, // 房间别名
        ext: "",
      };
      nim
        .signalingCreate(param)
        .then((data) => {
          console.warn("独立呼叫信令，创建房间成功，data：", data);
          this.channelId = data.channelId;
          this.joinRoom(data.channelId);
        })
        .catch((error) => {
          console.warn("独立呼叫信令，创建房间失败，error：", error);
          if (error.code == 10405) {
            console.warn("独立呼叫信令：房间已存在，请勿重复创建");
          }
        });
    },
    getInvitation() {
      var pushInfo = {
        needPush: true,
        pushTitle: "通话邀请",
        pushContent: "465邀请你接电话",
        pushPayload: { from: 456 },
        needBadge: true,
      };
      var param = {
        channelId: this.channelId, //创建的频道房间id
        account: this.accountId, //被邀请的id账号
        requestId: this.accountId, //
        offlineEnabled: true, //是否存离线通知
        attachExt: "请求接电话！", //自定义信息
        pushInfo: pushInfo, //推送消息
      };
      console.log(param);
      nim
        .signalingInvite(param)
        .then((data) => {
          console.warn("独立呼叫信令，邀请别人加入频道成功，data：", data);
          var ele = data.channelId; //房间id
          getJoinRoom(ele); //初始化本地流并且发布
        })
        .catch((error) => {
          console.warn("独立呼叫信令，邀请别人加入频道失败，error：", error);
          switch (error.code) {
            case 10404:
              console.warn("独立呼叫信令：频道不存在");
              break;
            case 10406:
              console.warn("独立呼叫信令：不在频道内（自己）");
              break;
            case 10407:
              console.warn("独立呼叫信令：已经在频道内（对方）");
              break;
            case 10419:
              console.warn("独立呼叫信令：频道人数超限");
              break;
            case 10201:
              console.warn("独立呼叫信令：对方云信不在线");
              break;
            case 10202:
              console.warn("独立呼叫信令：对方推送不可达"); //  可能对方不在线也是返回的这个。
              break;
          }
        });
    },
    joinRoom() {
      //自己加入房间
      var param = {
        channelId: this.channelId || null,
        offlineEnabled: false,
        attachExt: "",
        uid: "123",
      };
      nim
        .signalingJoin(param)
        .then((data) => {
          console.warn("独立呼叫信令，加入频道成功，data：", data);
          this.getInvitation(); //邀请对方加入房间实现通话
        })
        .catch((error) => {
          console.warn("独立呼叫信令，加入频道失败，error：", error);
          switch (error.code) {
            case 10407:
              console.warn("独立呼叫信令：已经在频道内");
              break;
            case 10419:
              console.warn("独立呼叫信令：频道人数超限");
              break;
            case 10417:
              console.warn("独立呼叫信令：频道成员uid冲突了");
              break;
            case 10420:
              console.warn(
                "独立呼叫信令：该账号，在其他端已经登录，并且已经在频道内"
              );
              break;
            case 10404:
              console.warn("独立呼叫信令：频道不存在");
              break;
          }
        });
    },

    //取消邀请
    getSignalingCancel() {
      var param = {
        channelId: this.channelId, //频道 房间id
        account: this.accountId, //被邀请的id
        requestId: this.accountId, //邀请者邀请的请求id
        offlineEnabled: true, //是否存离线通知
        attachExt: "取消了邀请", //操作者（即被邀请者）附加的自定义信息，透传给其他人，可缺省
      };

      nim
        .signalingCancel(param)
        .then((data) => {
          console.warn("独立呼叫信令，取消邀请别人加入频道成功，data：", data);
        })
        .catch((error) => {
          console.warn(
            "独立呼叫信令，取消邀请别人加入频道失败，error：",
            error
          );
          switch (error.code) {
            case 10404:
              console.warn("独立呼叫信令：频道不存在");
              break;
            case 10408:
              console.warn("独立呼叫信令：邀请不存在或已过期");
              break;
            case 10409:
              console.warn("独立呼叫信令：邀请已经拒绝");
              break;
            case 10410:
              console.warn("独立呼叫信令：邀请已经接受");
              break;
          }
        });
    },
    // ==============
    // 获取云端的消息记录
    getMessage(sceno) {
      let that = this;
      nim.getHistoryMsgs({
        scene: sceno,
        to: that.accountId,
        start:
          new Date(new Date().toLocaleDateString()).getTime() -
          7 * 24 * 3600 * 1000,
        endTime: Date.parse(new Date()),
        done: getHistoryMsgsDone,
      });
      function getHistoryMsgsDone(error, obj) {
        console.log(
          "获取云端历史记录" + (!error ? "成功" : "失败"),
          error,
          obj
        );
        if (!error) {
          console.log(obj.msgs);
          if (obj.msgs.length > 0) {
            pushMsg(obj.msgs);
            that.scrollToBottom(); //获取到消息记录 滚动条定位到最底部
            let text = sceno + "-" + that.accountId;
            let arrList = that.sessionsList[text];
            that.sessions = arrList;
            that.sessions.forEach((item) => {
              item.isbj = true;
            });
            that.GroupMessage = arrList;
          }
          console.log(that.sessions);
        } else {
          console.log("获取失败");
        }
      }
    },

    listen(event) {
      if (event.keyCode === 13) {
        this.sendCustomMsg(); // 发送文本
        event.preventDefault(); // 阻止浏览器默认换行操作
        return false;
      }
    },
    dialogue(row, index) {
      //私聊
      console.log(row);
      if (row.lastMsg.scene == "team") {
        this.accountName = "群聊" + row.to;
      } else {
        this.accountName = row.lastMsg.fromNick;
      }
      this.accountId = row.to;
      this.scenario = row.scene; //场景
      this.infoClass = row.id; //样式
      this.active = index; //样式
      this.getMessage(this.scenario);
    },
    // 群聊
    getGroup(row) {
      console.log(row);
      this.accountName = row.name;
      this.accountId = row.teamId;
      this.scenario = "team"; //场景
      this.getMessage(this.scenario);
    },
    GroupMsg() {
      //发送信息
      if (this.accountId == "" || this.accountId == null) return;
      if (this.textarea == "" || this.textarea == null || this.textarea == "\n")
        return;
      var content = this.textarea;
      let scene = "team";
      var msg = nim.sendText({
        // 'p2p' (单人聊天)
        // 'team' (群聊)
        // 'superTeam' (超大群聊天)
        scene: scene,
        to: this.accountId,
        text: content, //可用于搜索
        done: sendMsgDone,
      });
      let that = this;
      function sendMsgDone(error, obj) {
        console.log(error);
        console.log(obj);
        that.textarea = "";
        that.getMessage(scene); //发送完 更新
        pushMsg(msg);
      }
    },

    sendCustomMsg() {
      //发送信息
      if (this.accountId == "" || this.accountId == null) return;
      if (this.textarea == "" || this.textarea == null || this.textarea == "\n")
        return;
      var content = this.textarea;
      let scene = this.scenario;
      var msg = nim.sendText({
        // 'p2p' (单人聊天)
        // 'team' (群聊)
        // 'superTeam' (超大群聊天)
        scene: scene,
        to: this.accountId,
        text: content, //可用于搜索
        done: sendMsgDone,
      });
      let that = this;
      function sendMsgDone(error, obj) {
        console.log(error);
        console.log(obj);
        that.textarea = "";
        that.getMessage(scene); //发送完 更新
        pushMsg(msg);
      }
    },

    downTeam() {
      this.intercom = true;
      this.intercomText = "这在对讲...";
    },
    seupTeam() {
      this.intercom = false;
      this.intercomText = "按住讲话";
    },
    getVoice() {
      //点击讲话
      this.Voice = !this.Voice;
      this.mouseupVoice();
    },
    // 播放录音
    onPlayAudio() {
      this.isVoice = false;
      this.isFinished = true;
      this.audio = document.getElementById("audioVoice");
      this.recorder.play(this.audio);
    },

    // 停止播放录音
    onStopAudio() {
      this.recorder.clear(this.audio);
    },
    // 结束录音
    onEndVoice() {
      this.Voice = true;
      this.isFinished = true;
      let than = this;
      this.recorder.stopRecord({
        success: (res) => {
          //此处可以获取音频源文件(res)，用于上传等操作
          console.log("音频源文件", res);
          this.FileInformation = res;
          this.Voice = true;
        },
        error: (e) => {
          this.Voice = false;
        },
      });
      console.log(this.FileInformation);
      // than.$message.error(this.FileInformation);
     
      nim.sendFile({
        scene: this.scenario,
        to: than.accountId,
        type: "audio",
        blob: than.FileInformation,
        beginupload: function (upload) {
          // - 如果开发者传入 fileInput, 在此回调之前不能修改 fileInput
          // - 在此回调之后可以取消图片上传, 此回调会接收一个参数 `upload`, 调用 `upload.abort();` 来取消文件上传
        },
        uploadprogress: function (obj) {
          console.log("文件总大小: " + obj.total + "bytes");
          console.log("已经上传的大小: " + obj.loaded + "bytes");
          console.log("上传进度: " + obj.percentage);
          console.log("上传进度文本: " + obj.percentageText);
        },
        uploaddone: function (error, file) {
          console.log(error);
          console.log(file);
          console.log("上传" + (!error ? "成功" : "失败"));
        },
        beforesend: function (msg) {
          console.log("正在发送p2p audio消息, id=" + msg.idClient);
          pushMsg(msg);
        },
        done: sendMsgDone,
      });
      function sendMsgDone(error, msg) {
        console.log(
          "发送" +
            msg.scene +
            " " +
            msg.type +
            "消息" +
            (!error ? "成功" : "失败") +
            ", id=" +
            msg.idClient,
          error,
          msg
        );
      }
    },
    mouseupVoice() {
      // this.Voice = true;
      // 开始录音
      // this.onStopAudio();
      this.isFinished = false;
      this.recorder.startRecord({
        success: (res) => {
          console.log(res);
          this.FileInformation = res;
        },
        error: (e) => {
          this.Voice = false;
          console.log(e);
          // this.$toast(e);
        },
      });
    },

    handleClose(val) {
      this.account = "";
      this.dialogVisible = false;
    },
    handleOpen(val) {
      //展开
      console.log(val);
    },
  },
  watch: {
    sessionsList: {
      //刷新 信息
      handler(newVal, oldVal) {
        console.log(newVal);
        let text = "p2p" + "-" + this.accountId;
        let texts = "team" + "-" + this.accountId;
        let arrList = newVal[text];
        let arrLists = newVal[texts];
        this.sessions = arrList;
        this.GroupMessage = arrLists;

        this.scrollToBottom();
      },
    },
    RecentlyList: {
      handler(newVal, oldVal) {
        this.Recently = newVal;
      },
    },
    users: {
      handler(newValue, oldVal) {
        for (let i = 0; i < newValue.length; i++) {
          if (this.usersList[i] != newValue[i]) {
            this.usersList = newValue;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    tabsList() {
      console.log(this.$store.getters.tabsList);
      return this.$store.getters.tabsList;
    },
    sessionsList() {
      let sum = this.$store.getters.sessionsList;
      console.log(sum);
      return sum;
    },
    // sessions() {
    //   //会话列表
    //   return this.$store.getters.sessions;
    // },
    RecentlyList() {
      return this.$store.getters.RecentlyList;
    },
    users() {
      return this.$store.getters.users;
    },
  },
  updated() {
    // 聊天定位到底部
    // var div = document.getElementById("main"); //滚动条 最底部
    // div.scrollTop = div.scrollHeight;
    // console.log(div.scrollTop);
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #ffffff;
  width: 100%;
  height: 80rem;
  padding: 2rem;
  .el-aside {
    overflow: hidden !important;
    background-color: #ffffff;
    border: 1px solid #ececec;
    .input {
      padding: 1.4rem 1.1rem;
      background: #eef0f3;
      /deep/ .el-input .el-input__inner {
        background-color: #ffffff !important;
        border: 1px solid #ededed;
      }
    }
    .asideTab {
      width: 100%;
      height: 4.5rem;

      cursor: pointer;
      .el-tabs {
        width: 100%;
        height: 100%;
        /deep/.el-tabs__header {
          margin: 0 auto;
        }
        /deep/ .el-tabs__nav {
          width: 100%;
          border: 1px solid #ffffff;
        }
        /deep/.el-tabs__item {
          padding: 5px 35px;
          border-left: 1px solid #ffffff;
        }
      }
      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  .el-container {
    .el-header {
      position: relative;
      height: 7rem !important;
      background-color: #eef0f3;
    }
    .el-main {
      background-color: #ffffff;
    }
    .el-footer {
      background-color: #ffffff;
      border-top: 1px solid #ececec !important;
      border-bottom: 1px solid #ececec !important;
      border-right: 1px solid #ececec !important;
      height: 19.3rem !important;
    }
  }
}
.el-menu {
  width: 100%;
  background-color: #ececec;
  border-right: solid 1px #f7f7f7;
  font-size: 1.6rem !important;
  position: relative;
  .th {
    color: #333333;
    font-size: 1.6rem;
    margin-left: 2rem;
  }
  .td {
    position: absolute;
    // margin-right: 3rem;
    right: 2.5rem;
    font-size: 1.6rem;
    color: #999999;
  }

  /deep/ .el-submenu__title:hover {
    outline: 0 !important;
    background: #dedede !important;
  }
  /deep/ .el-submenu__title i {
    font-size: 18px;
    left: 20px;
  }
  /deep/ .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
    transform: rotate(180deg);
    // right: 0px;
    // left: 20px;
  }
  /deep/ .el-submenu__title .el-submenu__icon-arrow {
    right: 250px !important;
  }

  .el-menu-item:hover {
    outline: 0 !important;
    background: #dedede !important;
    color: #333333 !important;
  }
  .el-menu-item.is-active {
    color: #333333 !important;
    background: #dedede !important;
  }

  .el-menu-item.is-active {
    color: #333333;
  }

  .el-menu-item-group {
    width: 100%;
    background-color: #f7f7f7;
    .el-menu-item {
      width: 100%;
    }
    .img {
      width: 23%;
      height: 100%;
      border-radius: 50%;
    }
    .text {
      font-size: 1.4rem;
    }
    .online {
      color: #498dfe;
      font-size: 1.4rem;
      float: right;
    }
    .offline {
      color: #666666;
      font-size: 1.4rem;
      float: right;
    }
    .Head {
      display: inline-block;
      height: 3.8rem;
      width: 3.8rem;
      background-color: #49a9fe;
      border-radius: 4px;
      color: #ffffff;
      font-weight: 300;
      text-align: center;
      line-height: 3.8rem;
    }
  }
}
.el-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    display: block;
    height: 100%;
    text-align: center;
    line-height: 7rem;
    font-size: 1.8rem;
  }
}
.el-footer {
  .fun {
    width: 100%;
    height: 20%;
    padding-left: 1.5rem;
    display: flex;
    justify-content: center;
    .li {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      img {
        width: 2.6rem;
        height: 2.2rem;
        cursor: pointer;
      }
      .img-cetr {
        width: 2.1rem;
        height: 2.2rem;
        margin-left: 2.9rem;
      }
      .img-left {
        width: 2.2rem;
        height: 2.71rem;
        img {
          width: 100%;
          height: 100%;
        }
        .time {
          display: flex;
          justify-content: center;
          flex-direction: column;
          position: relative;
          .voiceong {
            display: block;
            width: 9.2rem;
            height: 3rem;
            background-image: url("~@/assets/comm/statebg.png");
            z-index: 999;
            position: absolute;
            top: -4.2rem;
            left: -3.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 60%;
              height: 60%;
              margin: 0 auto;
            }
          }
        }
      }
      .img-right {
        position: absolute;
        right: 15px;
      }
    }
  }
  .inputDeep {
    height: 80%;
    /* 如果你的 el-input type 设置成textarea ，就要用这个了 */
    /deep/ .el-textarea .el-textarea__inner {
      border: 0;
      resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
      background-color: #ffffff;
      font-family: "Alibaba-PuHuiTi-Regular";
    }
    .btn {
      width: 100%;
      height: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .el-button {
        width: 9.8rem;
        height: 3rem;
        padding: 7px 25px 7px 25px !important;
        background-color: #ffffff;
        border: 1px solid #ffffff;
        color: #333333;
      }

      .send {
        background-color: #d9f4fd;
        border: 1px solid #d9f4fd;
      }
      .sends {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        color: #dbdbdb;
      }
    }
  }
}
.content {
  width: 100%;
  // height: 100%;
  position: relative;
  margin-bottom: 5rem;
  .closed {
    width: 100%;
    margin-top: 1rem;
    position: relative;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s ease-out;
    img {
      border-radius: 50%;
      width: 4rem;
      height: 100%;
    }
    .Head {
      display: inline-block;
      height: 3.8rem;
      width: 3.8rem;
      background-color: #49a9fe;
      border-radius: 4px;
      color: #ffffff;
      font-weight: 300;
      text-align: center;
      line-height: 3.8rem;
    }
    .itemText {
      margin-left: 1rem;
      font-size: 1.4rem;
      color: #333333;
      text-align: center;
      padding: 5px;
      border-radius: 5px;
      background-color: #f1f1f1;
      display: inline-block;
      width: auto;
      word-wrap: break-word;
      white-space: normal;
      min-width: 30px;
      position: relative;
      line-height: 3rem;
    }
    .itemText::after {
      content: "";
      left: -14px;
      border: 7px solid transparent;
      border-right-color: #f1f1f1;
      position: absolute;
      top: 10px;
    }
    .itemAudio {
      margin-left: 1rem;
      font-size: 1.4rem;
      color: #333333;
      text-align: center;
      padding: 5px;
      border-radius: 5px;
      background-color: #f1f1f1;
      display: inline-block;
      width: auto;
      word-wrap: break-word;
      white-space: normal;
      min-width: 6rem;
      position: relative;
      line-height: 3rem;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // .interpret {
      // }
      img {
        width: 2rem;
        height: 2rem;
      }
    }
    .itemAudio::after {
      content: "";
      left: -14px;
      border: 7px solid transparent;
      border-right-color: #f1f1f1;
      position: absolute;
      top: 10px;
    }
  }
  .hair {
    width: 100%;
    margin-top: 1rem;
    position: relative;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    transition: all 0.3s ease-out;
    img {
      border-radius: 50%;
      width: 4rem;
      height: 100%;
    }
    .Head {
      display: inline-block;
      height: 3.8rem;
      width: 3.8rem;
      background-color: #49a9fe;
      border-radius: 4px;
      color: #ffffff;
      font-weight: 300;
      text-align: center;
      line-height: 3.8rem;
    }
    .itemText {
      margin-right: 1rem;
      font-size: 1.4rem;
      color: #333333;
      text-align: center;
      padding: 5px;
      border-radius: 5px;
      background-color: #c5e4ff;
      display: inline-block;
      width: auto;
      word-wrap: break-word;
      white-space: normal;
      min-width: 30px;
      min-height: 30px;

      position: relative;
      line-height: 3rem;
    }
    .itemText::after {
      content: "";
      right: -14px;
      border: 7px solid transparent;
      border-left-color: #c5e4ff;
      position: absolute;
      top: 10px;
    }
    .itemAudio {
      margin-right: 1rem;
      font-size: 1.4rem;
      color: #333333;
      text-align: center;
      padding: 5px;
      border-radius: 5px;
      background-color: #c5e4ff;
      display: inline-block;
      width: auto;
      word-wrap: break-word;
      white-space: normal;
      min-width: 6rem;
      position: relative;
      line-height: 3rem;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row-reverse;
      // .interpret {
      // }
      img {
        width: 2rem;
        height: 2rem;
        transform: rotateY(180deg);
      }
    }
    .itemAudio::after {
      content: "";
      right: -14px;
      border: 7px solid transparent;
      border-left-color: #c5e4ff;
      position: absolute;
      top: 10px;
    }
  }
  .time {
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      color: #999999;
      font-size: 12px;
    }
  }
}
.empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1.8rem;
    color: #c0c4cc;
  }
}

.wapr {
  position: relative;
  top: 0;
  left: 0;
  .accountName {
    position: fixed;
    z-index: 99999;
  }
}

/*滚动条样式*/
.el-main::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.el-main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.8);
  background: #999b9d;
  cursor: pointer;
}
.el-main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.8);
  border-radius: 0;
  background: #f3f3f3;
}
/deep/ .el-dialog {
  background-color: #131f2b !important;
  border-radius: 1.2rem;
  height: 40%;
  min-width: 320px;
  min-height: 320px;
}

.local-container {
  width: 500px;
  height: 500px;
  float: right;
}
.sessionWarp {
  width: 100%;
  .delimit {
    background: #ececec;
  }
  .active {
    background: #ececec;
  }
  .sessionLi {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 10px;

    .Head {
      display: inline-block;
      height: 3.8rem;
      width: 3.8rem;
      background-color: #49a9fe;
      border-radius: 4px;
      color: #ffffff;
      font-weight: 300;
      text-align: center;
      line-height: 3.8rem;
    }
    .p {
      margin-left: 1rem;
    }
    .Group {
      .title {
        font-size: 1.6rem;
        color: #333333;
        margin-left: 1rem;
      }
    }
    .sessionSpan {
      position: relative;
      .title {
        font-size: 1.6rem;
        color: #333333;
        margin-left: 1rem;
        max-width: 15rem;
        overflow: hidden;
        text-overflow: ellipsis; /* 加省略号 */
        white-space: nowrap; /* 强制不换行 */
      }
      .rem {
        font-size: 1.2rem;
        color: #999999;
      }
      span {
        display: block;
      }
    }
    .state {
      position: absolute;
      right: 1.6rem;
      font-size: 1.4rem;
      .isTrue {
        color: #498dfe;
      }
      .isFalse {
        color: #999999;
      }
    }
  }
}

.addressBook {
  width: 100%;
  padding: 20px 0px;
  .add {
    width: 100%;
    height: 3.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    .th {
      background: #49a9fe;
      border-radius: 50%;
      width: 3.8rem;
      height: 3.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
      }
    }
    span {
      font-size: 1.6rem;
      margin-left: 1rem;
    }
  }
  .add:hover {
    background-color: #ececec;
  }

  .FriendsList {
    width: 100%;
    .FriendsLi {
      padding: 20px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .Head {
        display: inline-block;
        height: 3.8rem;
        width: 3.8rem;
        background-color: #49a9fe;
        border-radius: 4px;
        color: #ffffff;
        font-weight: 300;
        text-align: center;
        line-height: 3.8rem;
      }
      .title {
        font-size: 1.6rem;
        color: #333333;
        margin-left: 1rem;
        max-width: 15rem;
        overflow: hidden;
        text-overflow: ellipsis; /* 加省略号 */
        white-space: nowrap; /* 强制不换行 */
      }
    }
    .acticn {
      background: #ececec;
    }
    .addCticn {
      background: #ececec;
    }
  }
}

.dialogAdd {
  border-radius: 5px;
  /deep/ .el-dialog {
    background-color: #ffffff !important;
    border-radius: 5px;
    height: 20%;
    min-width: 320px;
    min-height: 250px;
    .el-dialog__header {
      background-color: #e5f4ff;
      border-radius: 5px;
    }
    .el-dialog__footer {
      display: flex;
      justify-content: center;
      padding: 0;
      border-top: 1px solid #e5f4ff;
      .dialog-footer {
        margin-top: 20px;
      }
    }
  }
  .findInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      display: block;
      width: 5.6rem;
      height: 5.6rem;
    }
    .text {
      color: #3a4a59;
      font-size: 1.6rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      .s1 {
        font-size: 1.4rem;
        color: #6b8299;
      }
      span {
        margin-left: 1rem;
      }
    }
  }
}
.dialogGroup {
  overflow-y: scroll;
  /deep/ .el-dialog {
    background-color: #ffffff !important;
    border-radius: 5px;
    height: 20%;
    .el-dialog__header {
      background-color: #e5f4ff;
      border-radius: 5px;
    }
  }
}
.GroupWarp {
  width: 100%;
  height: 100%;
  position: 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .li {
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    img {
      width: 5rem;
      height: 5rem;
    }
    span {
      font-size: 14px;
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
.GroupData {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
</style>

